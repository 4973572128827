@media screen and (max-width: 425px) {
  .title {
    //font-size: 20px !important ;
    padding: 10px;
    .coreValueTitle {
      font-size: 25px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
    padding: 10px;
    .coreValueTitle {
      font-size: 25px !important;
    }
  }
  .p-dialog-title {
    font-size: 21px !important;
  }

  .popup-content ul {
    font-size: 19px;
  }
}

@media screen and (min-width: 426px) and (max-width: 770px) {
  .title {
    font-size: 32px;
  }
}

@media screen and (min-width: 850px) {
  .stepperHolder {
    margin: auto;
  }
}

@media screen and (max-width: 850px) {
  .stepperHolder {
    margin-right: unset;
  }
}
