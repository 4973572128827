@media screen and (max-width: 1240px) {
  .coreValueContainer {
    .containerHolder {
      display: flex;
      flex-direction: column;
    }
  }
  .coreValueImage {
    width: 100% !important;
    display: flex;
    justify-content: center;
    img {
      min-width: unset !important;
      width: 80vw !important;
      margin-top: 30px;
    }
  }
  .coreValueContainer .containerHolder .container {
    width: 100% !important;
  }

  #resultPopup {
    width: 90% !important;
  }
}

@media screen and (max-width: 1140px) {
  .containerHeaderHolder.testContainer {
    gap: 10px;
    .content {
      min-height: 150px !important;
      text-align: center;
    }
    .testButtonHolder {
      min-height: 150px !important;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: justify;
    }
    .requestButton {
      max-width: unset !important;
      width: 100% !important;
    }

    .purchaseButton {
      margin: 20px 0px !important;
      width: 70%;
      max-width: 230px;
    }
    .main-header {
      font-size: 31px;
      padding: 0px 30px;
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 1140px) {
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link {
    padding: 0.75rem 1rem !important;
  }
}

@media screen and (max-width: 960px) {
  .p-menuitem {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .p-menuitem-link {
    justify-content: center !important;
  }

  .p-submenu-icon {
    display: none !important;
  }

  .p-menuitem-icon {
    display: none !important;
  }
  .card .p-menubar .p-menuitem-link {
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .missionVisionContainer {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background-color: #d9f2ff;
    align-items: center;
    height: fit-content;
    .missionVisionBg {
      display: none;
    }

    .mission {
      padding: 30px 30px 30px 30px !important;
    }
  }
  .catalogueBg .content {
    padding: 0px 60px !important;
  }

  .socialHolder .container {
    width: 40% !important;
  }
  .TestPage {
    .contentTestDesktop {
      display: none !important;
    }
    .contentTestMobile .content {
      width: 80%;
      font-size: 23px;
    }
  }

  .stepperHolder {
    width: 100%;
    overflow-y: hidden;
    padding: 20px;
    .testExplain {
      width: 100% !important;
    }
    .p-stepper-nav {
      width: 100% !important;
    }
  }

  canvas {
    width: 95% !important;
    height: auto !important;
  }

  #purchasePopup {
    width: 90% !important;
  }

  #ratingPopUp {
    width: 90% !important;
  }

  .address {
    width: 100% !important;

    .inputHolder,
    .emailContent {
      width: 80% !important;
    }
  }
}

@media screen and (min-width: 850px) {
  .TestPage {
    .contentTestMobile {
      display: none !important;
    }
    .MuiStepLabel-iconContainer {
      svg {
        transform: scale(1.2);
      }
    }
  }
  .p-stepper-nav {
    width: 92% !important;
  }

  #warningPopup {
    width: 50% !important;
  }
  #resultPopup {
    width: 70% !important;
  }
}

@media screen and (max-width: 700px) {
  .test {
    .testContainerHolder {
      top: 10%;
      img {
        height: 40px;
      }

      .main-header {
        font-size: 35px;
      }

      .sub-header {
        font-size: 15px;
      }
    }
  }

  .testContainer {
    padding: 10px;
    .main-header {
      z-index: 10;
      font-size: 37px;
      font-weight: 600;
    }
    .sub-header {
      z-index: 10;
      font-size: 17px;
      font-weight: 400;
      max-width: 850px;
      margin: 10px;
    }
  }
  .furtherInfo {
    min-height: 35vh;
    background-size: 230%;
    width: 100%;
    height: 100%;
    max-height: 35vh;
    background-position-x: 13%;
    background-position-y: 90%;
  }
  .catalogueBg {
    min-height: 60vh;
  }
}

@media screen and (max-width: 650px) {
  .catalogueBg {
    display: flex;
    flex-direction: column !important;

    .content {
      padding: 0px 60px !important;
      margin: auto;
      max-width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center !important;
    }
  }

  #barGraphPopup {
    .p-dialog-title {
      font-size: 16px !important;
    }
  }

  .catalogueBg .studentContainer {
    position: unset !important;
  }

  .catalogueBg .studentContainer .student {
    width: 200px;
    margin-bottom: 60px;
    display: none !important;
  }
}

@media screen and (max-width: 611px) {
  .socialHolder .container {
    width: 80% !important;
  }
}

@media screen and (max-width: 600px) {
  .titleSocial,
  .furtherInfo {
    padding: 0px 15px !important
    ;
  }
  .ringsHolder {
    display: none;
  }
  .requestButton {
    max-width: unset !important;
    width: 100% !important;
  }

  .MuiPickersCalendarHeader-iconButton {
    font-size: 24px; /* Increase the size of the arrow icons */
  }

  /* Mission & Vision */
  .missionVisionContainer .missionVision {
    align-items: center;
    width: unset !important;
    margin: auto;

    .contentHolder {
      img {
        width: 100%;
        margin-bottom: 15px;
      }

      .text {
        font-size: 13px;
        line-height: 22px;
      }
    }
  }

  .coreValueContainer {
    .containerHolder {
      .card {
        min-height: 200px;
        width: 350px;

        .title {
          font-size: 18px;
          margin-bottom: 10px;
        }
        .description {
          font-size: 12px;
        }

        &.pop {
          animation: pop 2s ease-out forwards;
        }
      }
    }
  }
  .footer {
    .row {
      flex-direction: column;
      .logo {
        margin-bottom: 15px;
      }

      .title {
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
      }

      .text {
        margin-bottom: 8px;
      }
      .align {
        padding: 0px 15px !important;
        min-width: 180px;
      }
    }
  }

  .coreValueImage {
    display: none !important;
  }

  .socialHolder .container {
    width: 90% !important;
  }

  #barGraphPopup {
    width: 90% !important;
  }

  #contentPopup {
    width: 90% !important;
  }

  canva {
    height: 250px !important;
  }
  .popupButton {
    height: 30px !important;
    font-size: 17px;
    outline: none;
  }
  .p-chart {
    padding: 0px 10px !important;
  }

  #ratingPopUp {
    width: 90% !important;
  }
}

@media screen and (max-width: 500px) {
  .stepperButton {
    height: 28px !important;
    font-size: 19px;
  }

  .containerHeaderHolder .main-header {
    font-size: 40px !important;
    padding: 0px 10px !important;

    &.maindemoheader {
      font-size: 33px !important;
    }
  }

  .containerHeaderHolder .sub-header {
    font-size: 15px !important;
    padding: 0px 10px !important;
  }

  .requestButton {
    max-width: unset !important;
    width: 100% !important;
  }
  .stepperHolder {
    width: 100% !important;

    .p-stepper-title {
      font-size: 13px;
    }

    .pi {
      font-size: 15px;
    }
    .checkIcon {
      width: 35px !important;
      padding: 0px 0px 4px 5px !important;
    }

    .card {
      padding: 10px 5px !important;
    }

    .card label {
      font-size: 12px;
    }

    .field-checkbox {
      display: flex;
      align-items: center;
    }

    .p-stepper .p-stepper-panels {
      padding: 0 !important;
    }

    .p-checkbox {
      width: 20px !important;
      height: 18px !important;
    }

    .muiStepperContainer {
      .muiStepperLabel {
        .css-2ulfj5-MuiTypography-root,
        .MuiStepLabel-label {
          font-size: 15px !important;
          &.Mui-active {
            font-weight: bold !important;
            font-size: 15px !important;
          }
        }
      }
      .muiStepperContentHolder {
        .muiStepperContent {
          .muiStepperOptions {
            .muiStepperOptionLabel {
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }
  .panelButton {
    width: 90%;
    height: 35px;
    margin: auto !important;
    display: flex;
  }

  .panelButtonHolder {
    margin-top: 20px;
    text-align: center;
  }

  .quiz-definitions {
    margin: 20px;
    font-family: Arial, sans-serif;
  }

  .quiz-definition {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

  .quiz-definitions h4 {
    font-size: 17px;
    font-weight: 600;
  }

  .quizTitlePopup {
    font-size: 15px;
    font-weight: bold;
  }

  .quizDescriptionPopup {
    font-size: 13px;
  }

  #barGraphPopup {
    .p-dialog-title {
      font-size: 20px !important;
    }
  }

  #resultPopup {
    .p-dialog-title {
      font-size: 19px !important;
    }

    .popup-content {
      padding: 10px;

      .no-results-message {
        font-size: 17px !important;
      }

      .sub-header {
        font-size: 18px !important;
      }

      .alternative-majors-message,
      .encouragement-message {
        font-size: 17px !important;
      }
      ul li {
        font-size: 15px !important;
      }
    }

    .share-results-button {
      margin: 20px auto 20px !important;
    }
    .p-dialog-content p {
      padding: 0px 15px !important;
    }
  }

  .p-toast {
    width: 300px !important;
    font-size: 14px !important;
    .p-toast-message-content {
      padding: 7px 5px;
      .p-toast-detail {
        font-size: 13px !important;
        margin: 0;
      }
    }
  }

  .download-button-container,
  .furtherInfo {
    .button {
      max-width: 255px;
      font-size: 14px;
    }
  }

  .title {
    font-size: 22px;
    margin-bottom: 13px;
    padding: 10px 10px !important;
  }

  .description {
    &.descriptionCatalogue {
      font-size: 14px;
    }
  }

  .furtherInfo {
    font-size: 14px;
  }

  .coreValueContainer .containerHolder {
    //padding-top: 50px;
  }
  .requestButton {
    max-width: unset !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 425px) {
  .containerHeaderHolder.testContainer {
    top: 7% !important;
  }

  .catalogueBg {
    max-height: 35vh;
    .content {
      padding: 0px 30px !important;
      .title {
        font-size: 24px !important;
      }
    }
  }

  .download-button-container,
  .furtherInfo {
    padding: 0px 10px;
    .button {
      max-width: 255px !important;
      font-size: 14px !important ;

      &.requestButton {
        max-width: unset !important;
        width: 100% !important;
      }
    }
  }
  .title {
    font-size: 21px !important ;
  }

  .description {
    &.descriptionCatalogue {
      font-size: 12px !important;
      margin-bottom: 10px;
    }
  }

  .furtherInfo {
    font-size: 14px;
    background-position-x: 13%;
    background-position-y: 60%;
  }
}

@media screen and (max-width: 420px) {
  .contentTestMobile {
    .content {
      width: 85% !important;
      font-size: 20px !important;
    }
  }

  .button.popupButton {
    min-width: 125px !important;

    font-size: 16px !important;
  }
  .requestButton {
    max-width: unset !important;
    width: 100% !important;
  }
}
