body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter var', sans-serif;
}

body[dir='rtl'] {
  direction: rtl;
  text-align: right;
}

body[dir='ltr'] {
  direction: ltr;
  text-align: left;
}

.highlight {
  color: #aedcf7;
  &.main {
    color: #0056b3;
    font-weight: 500;
  }
}

.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  display: none !important;
}

.css-lc42l8-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 10px !important;
}

.MuiStack-root css-10o2lyd-MuiStack-root {
  width: 100% !important;
  height: 35px !important;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.css-10o2lyd-MuiStack-root {
  overflow: unset !important;
}
// .p-stepper-nav {
//   pointer-events: none;
// }

.button {
  padding: 12px 12px;
  font-size: 18px;
  color: white;
  border: none !important;
  border-radius: 30px;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  box-shadow: none !important;

  &.thin {
    height: 37px;
    width: 183px;
    font-size: 16px;
    justify-content: center;
    margin-bottom: 10px;
  }
  &.green {
    background-color: #00cd21 !important;
  }
  &.popupButton {
    height: 40px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    max-width: 200px;
    min-width: 145px;
    outline: none !important;
    width: 45%;
  }
}

.button i {
  margin-right: 8px;
  outline: none !important;
}

.button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
  outline: none !important;
}

.panelButtonHolder {
  outline: none;
  padding: 17px;
}

// .stepperButton {
//   height: 35px !important;
//   font-size: 13px;
//   outline: none;
// }

// Header
.mainHeader {
  .header {
    height: 100vh;
    background-image: url('..\\..\\public\\images\\Teenage.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.menuBar {
  position: fixed;
  z-index: 10;
  width: 100%;
  z-index: 100;
  opacity: 15;

  &.slideDown {
    animation: slideDown 0.5s ease-out;
  }
}

.card {
  width: 100%;
  border-radius: 0;
  border: none !important;
  .logo {
    height: 35px;
  }

  .p-menubar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0px;
    background-color: #e7f7ff;
    border: none;
    padding: 0px 20px;
    height: 55px;
    z-index: 10;

    .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
      color: #4b5563;
      transition: box-shadow 0.2s;
      border-radius: 0px;
      height: 100%;
    }
    .p-menubar-root-list {
      margin: 0;
    }
    .p-menuitem-link {
      height: 55px;
      font-weight: 500;
      text-decoration: none;
    }
  }
}
.custom-menubar .p-menubar-root-list > .p-menuitem > .p-submenu-list {
  overflow: hidden;
  transition: max-height 5s ease-in-out;
  z-index: 20;
}
.custom-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-submenu-list {
  max-height: 500px;
  min-width: 220px;
}
.containerHeaderHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  color: #ffffff;
  height: 100vh;
  background-image: url('..\\..\\public\\images\\Teenage.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;

  .contentHeader {
    &.fadeIn {
      animation: fadeIn 1s ease-in-out;
    }
  }

  .main-header {
    z-index: 10;
    font-size: 55px;
    font-weight: 600;
  }
  .sub-header {
    z-index: 1 !important;
    font-size: 20px;
    font-weight: 400;
    max-width: 850px;
    margin: 10px;
  }

  &.fadeIn {
    animation: fadeIn 1s ease-in-out;
  }
  &.testContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    text-align: start;
    flex-wrap: wrap;
    height: fit-content;
    color: #263a5b;
    padding: 40px 0px 40px 0px;
    gap: 40px;

    &.adminPage {
      background-image: none !important;
      height: 200px !important;
    }

    .main-header {
      font-size: 31px;
      padding: 0px 45px;
    }

    .sub-header {
      font-size: 17px;
      padding: 0px 20px;
    }
    .content {
      width: 500px;
      min-height: 250px;
    }

    .testButtonHolder {
      text-align: start;
      width: 500px;
      min-height: 250px;
    }

    .purchaseButton {
      display: flex;
      margin: 20px 30px 0 30px !important;
      height: 35px;
      background-color: #007bff;
    }
  }

  &.personalityTest {
    background-image: url('..\\..\\public\\images\\personalityTest.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    display: flex;
    align-items: end;
    color: #263a5b;
    font-size: 20px;
    margin-right: 20px;
  }
}

// Mission & Vision
.missionVisionContainer {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #e4f6ff;
  flex-direction: column;
  background-image: url('..\\..\\public\\images\\MissionVisionBg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;

  .missionVision {
    align-items: center;
    width: 60%;
    margin: auto;
  }
  .missionVisionBg {
    display: flex;
    justify-content: end;
    object-fit: cover;
    position: relative;
    width: 50%;
    min-width: 500px;
  }

  .contentHolder {
    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .text {
      font-size: 14px;
      text-align: justify;
      line-height: 25px;
      color: #263a5b;
      font-weight: 400;
    }
  }

  .iconTextHolder {
    display: flex;
    justify-content: center;
    color: #758bee;
  }

  .holder {
    display: flex;
    height: fit-content;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    margin: auto;
    .mission {
      padding: 30px;
      border-radius: 8px;
      opacity: 0;
      margin: auto;
      &.slideIn {
        animation: slideIn 1s ease-out forwards;
      }
    }
  }
}

.mission-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px 0;
}

.line {
  flex: 1;
  border: none;
  border-top: 1px solid #758bee75;
  margin: 0 10px;
}

.mission-text {
  font-weight: bold;
  font-size: 25px;
  white-space: nowrap;
  color: #263a5b;
}

.vision {
  padding: 30px;
  border-radius: 8px;
  margin: auto;
  opacity: 0;

  &.slideInRight {
    animation: slideInRight 1s ease-out forwards;
  }
}
.coreValueHolderContainer {
  padding-bottom: 60px;
  padding-top: 60px;
}

.coreValueContainer {
  min-height: 80vh;
  height: fit-content;
  position: relative;
  background-color: #e4f6ff;

  .containerHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 55px;
    background-color: #fff;
    border-radius: 100px;
    margin: 0vw 3vw 0vw 3vw;

    .container {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      padding: 0;
    }

    .coreValueImage {
      width: 50%;
      display: flex;
      justify-content: center;
      img {
        min-width: 580px;
      }
    }

    .card {
      min-height: 200px;
      width: 280px;
      padding: 20px;
      border: none;
      background-color: #fff;

      img {
        width: 50px;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
      }
      .description {
        font-size: 12px;
        color: #555;
        opacity: 0.8;
        text-align: justify;
      }

      &.pop {
        animation: pop 2s ease-out forwards;
      }
    }
  }
  .ringsHolder {
    position: absolute;
    bottom: 5%;
    width: 100%;
    .rings {
      width: 200px;
      height: 200px;
    }
  }
  .coreValueBg {
    display: none;
  }
}

.footer {
  height: fit-content;
  background: #33435c;
  min-height: 35vh;
  // border-radius: 10px 10px 0px 0px;
  display: flex;
  z-index: 10;
  position: relative;
  align-items: center;
  justify-content: center;
  .handle {
    width: 90%;
  }
  .row {
    padding: 40px 20px 15px;
    justify-content: space-between;
    display: flex;
    // width: 85%;
    width: 100%;
    margin: 0;
    // margin: auto;

    text-align: justify;
    .logo {
      height: 40px;
      margin-bottom: 8px;
    }

    .title {
      font-weight: 500;
      margin-bottom: 20px;
      font-size: 18px !important;
      color: #ffffff;
    }

    .text {
      margin-bottom: 8px;
      text-decoration: none;
      color: #ffffff;
    }
    .align {
      padding: 0px 20px;
      //width: 17%;
      display: flex;
      flex-direction: column;
      color: #ffffff;
    }
    .footer-text {
      color: #ffffff;
      padding: 0;
    }
  }
}

.copyrightHandle {
  background: #33435c;
  margin: 0 !important;
  position: relative;

  .copyright {
    margin: 0 !important;
    color: #ffffff;
    padding: 4px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

// Test section
.test {
  height: fit-content;
  min-height: 80vh;
  background-image: url('..\\..\\public\\images\\Testbg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .testContainerHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    opacity: 0;

    .main-header {
      z-index: 10;
      font-size: 55px;
      font-weight: 600;
    }

    img {
      height: 70px;
    }
    .sub-header {
      z-index: 10;
      font-size: 20px;
      font-weight: 400;
      max-width: 850px;
      margin: 10px 10px 20px 10px;
    }

    &.fadeIn {
      animation: fadeIn 1s ease-in-out forwards;
    }
  }
}
.socialHolder {
  width: 100%;
  min-height: 70vh;
  background-image: url('..\\..\\public\\images\\services.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container {
    width: 90%;
    border-radius: 10px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: #c0e8ff;

    .component {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      min-width: 100px;
      padding: 20px;
      color: #8e8e90;
      .pi {
        font-size: 30px;
        margin-bottom: 15px;
      }

      .name {
        text-decoration: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 500 !important;
      }
    }

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .component:hover {
      color: #0056b3;
      transform: translateY(-3px);
    }
  }
}

.furtherInfo {
  min-height: 65vh;
  background-image: url('..\\..\\public\\images\\faqbg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}
// Test Page

// Tabs Panel
.p-tabview-nav {
  display: flex;
  justify-content: space-around;
}

.testContainer {
  background-image: url('..\\..\\public\\images\\Testbgpage.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.stepperHolder {
  width: 53%;
  //margin: auto;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
  padding: 60px 20px 60px 60px;
  position: relative;
  left: 0;
  right: 0;

  // button {
  //   border-radius: 30px;
  //   border: none;
  //   outline: none !important;
  // }

  // .checkIcon {
  //   width: 42px;
  //   padding: 0px 0px 8px 6px;
  // }

  // .field-checkbox {
  //   margin-bottom: 12px;
  // }

  .card {
    border: none;

    .card label {
      width: 90%;
    }

    .field-checkbox {
      display: flex;
      align-items: center;
    }

    // .p-stepper p-component p-stepper-vertical {
    //   width: none !important;
    // }

    .field-radiobutton {
      margin: 15px 0px;
    }
    label {
      padding: 0px 10px;
      margin-bottom: 0 !important;
    }
  }

  button {
    border-radius: 30px;
  }

  .backbtn {
    margin-right: 20px;
  }

  //new stepper
  .muiStepperContainer {
    width: 100%;
    font-size: 16px;

    .muiStepperLabel {
      display: flex;
      // align-items: flex-start;
      gap: 5px;
      padding: 5px 0;

      .css-2ulfj5-MuiTypography-root,
      .MuiStepLabel-label {
        font-size: 18px !important;
        &.Mui-active {
          font-weight: bold !important;
          font-size: 18px !important;
        }
      }
      .MuiStepLabel-iconContainer {
        padding: 2px;
        .MuiStepIcon-text {
          font-size: 14px;
        }
        .Mui-completed {
          color: #0056b3;
        }
        .Mui-active {
          color: #007bff;
        }
      }
    }

    .muiStepperContentHolder {
      border-left: none !important;
      border-right: none !important;
      .muiStepperContent {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &.careerTest {
          gap: 0px !important;
          svg {
            height: 28px;
            width: 28px;
          }
        }
        .muiStepperOptions {
          // border: 1px solid red;
          display: flex;
          margin: 2px;
          align-items: center;
          gap: 8px;

          .muiStepperOptionLabel {
            margin: 0;
            padding: 0;
            font-size: 18px !important;
          }

          .p-radiobutton.p-highlight .p-radiobutton-box {
            border-color: #007bff;
            background: #007bff;
          }

          input[type='radio']:focus {
            outline: none;
            box-shadow: none;
          }
          .p-radiobutton-box:hover {
            border-color: #007bff !important;
          }
        }
      }
    }
  }
  .muiStepperButtons {
    margin: 0 !important;

    .muiStepperSecondaryBtn,
    .muiStepperPrimaryBtn {
      margin-top: 8px !important;
      margin-right: 8px !important;
    }

    .muiStepperSecondaryBtn {
      color: #8e8e90;
    }
  }
  //old stepper

  // .p-stepper.p-stepper-vertical {
  //   width: 100% !important;
  // }

  // .p-stepper.p-stepper-vertical
  //   .p-stepper-panel:last-of-type
  //   .p-stepper-content {
  //   padding-left: 2rem !important;
  // }

  // .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
  //   padding-left: 1rem !important;
  // }

  // .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
  //   margin-inline-start: 13px !important;
  // }

  // .p-stepper-title {
  //   white-space: normal !important;
  //   text-align: start;
  // }

  .testExplain {
    width: 100%;
    margin-bottom: 25px;
    .title {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .subtitle {
      width: 100%;
      text-align: justify;
      color: #8e8e90;
      margin-bottom: 35px;
    }
  }
}

// Catalogue
.catalogueBg {
  min-height: 100vh;
  background-image: url('..\\..\\public\\images\\catalogue.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: row;
  direction: ltr !important;

  .content {
    max-width: 60%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0px 130px;

    .download-button-container {
      display: flex;
      justify-content: start;
      margin-top: 20px;
    }
  }

  .studentContainer {
    display: flex;
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    .student {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 300px;

      &.flipImage {
        display: inline-block;
        animation: flip 2s ease-in-out;
        transform-style: preserve-3d;
      }
    }
  }
}
.title {
  font-size: 37px;
  font-weight: 600;
  color: #263a5b;
  margin-bottom: 35px;

  &.titleSocial {
    display: flex;
    justify-content: center !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }

  &.coreValueTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 0 !important;
    padding-top: 30px;
  }

  &.catalogueTitle {
    text-align: start;
  }
}

.description {
  font-size: 16px;
  font-weight: 400;
  color: #263a5b;
  margin-bottom: 25px;

  &.descriptionSocial {
    display: flex;
    justify-content: center !important;
    max-width: 700px;
    text-align: center;
  }
  &.descriptionCatalogue {
    text-align: start;
  }
  &.descriptionFaq {
    text-align: center;
    color: #0056b3;
    font-weight: 700;
  }
}

//Loader Page
.loaderContainer {
  height: 100vh;
  background-image: url('..\\..\\public\\images\\loaderbg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

//Result Page
.resultPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f2f6;
  min-height: 100vh;
  color: #333;

  .heroSection {
    width: 100%;
    background-image: url('..\\..\\public\\images\\Teenage.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
    padding: 60px 20px;
    margin-bottom: 30px;
    position: relative;
    height: 40vh;
  }

  .heroTitle {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .heroSubtitle {
    font-size: 24px;
  }

  .content {
    width: 80%;
    max-width: 1200px;
    margin-top: -80px;
    padding: 30px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .chartContainer,
  .infoCard,
  .testimonialCard {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .chartContainer {
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
    background-color: #ffffff;
  }

  .infoSection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .infoCard {
    width: 48%;
    margin-bottom: 20px;
  }

  .infoCard h2 {
    font-size: 25px;
    color: #263a5b;
    margin-bottom: 15px;
  }

  .infoCard p {
    font-size: 16px;
    color: #555555;
  }

  .callToAction {
    background-color: #263a5b;
    color: #ffffff;
    text-align: center;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .callToAction h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .callToAction p {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  .ctaLink {
    color: #ffffff;
    text-decoration: underline;
    font-size: 1.1rem;
  }

  .testimonials {
    width: 100%;
    margin-bottom: 30px;
  }

  .testimonials h3 {
    font-size: 1.8rem;
    color: #263a5b;
    margin-bottom: 15px;
  }

  .testimonialCard {
    margin-bottom: 15px;
  }

  .testimonialCard p {
    font-size: 1.1rem;
    color: #333333;
    margin-bottom: 5px;
  }

  .testimonialCard span {
    font-size: 1rem;
    color: #263a5b;
  }

  .socialShare {
    text-align: center;
    margin-bottom: 30px;
  }

  .socialButtons {
    display: flex;
    justify-content: center;
  }

  .socialButton {
    background-color: #263a5b;
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    margin: 0 8px;
    text-decoration: none;
    font-size: 1.1rem;
    transition: background-color 0.3s, transform 0.2s;
  }

  .socialButton.facebook {
    background-color: #3b5998;
  }

  .socialButton.twitter {
    background-color: #1da1f2;
  }

  .socialButton.linkedin {
    background-color: #0077b5;
  }

  .socialButton:hover {
    background-color: #004d40;
    transform: scale(1.05);
  }

  .downloadButton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 15px 30px;
    background: #0056b3;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
    z-index: 10;
  }

  .downloadButton:hover {
    background: #263a5b;
    transform: scale(1.05);
  }
}

#addUserPopUp {
  width: 40% !important;
  .p-dialog-header {
    border: white;
    display: none;
  }
}

.loginDemoOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('..\\..\\public\\images\\loginbg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 10px 100px;
  max-height: 100vh;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;

  &.popUp {
    background-image: none !important;
    padding: 0 !important;
    max-height: unset !important;
    height: unset !important;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: unset !important;

    .formGroup {
      width: 100%;
    }
  }

  .pPassword {
    border: none !important;
    padding: 0 !important;
    box-shadow: 0 !important;
  }
  .pInputText {
    height: 35px;
  }
  input {
    height: 35px;
  }

  .pButtonPrimary,
  .pButtonSecondary {
    height: 35px;
  }
  .loginDemoContainer {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    transition: all 0.3s ease;
    padding: 20px;
    position: relative;
    &.signUp {
      align-self: start;
    }
  }

  .loginDemoContainer.login::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #007bff, #00d2ff);
    border-radius: 8px 8px 0 0;
    top: 0;
    left: 0;
  }

  .loginDemoContainer.signUp::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #28a745, #1c8c4c);
    border-radius: 8px 8px 0 0;
    top: 0;
    left: 0;
  }

  .loginDemoForm {
    display: flex;
    flex-direction: column;
  }

  h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
    font-size: 24px;
  }

  .formInstruction {
    text-align: center;
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .formGroup {
    margin-bottom: 15px;
  }

  .formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }

  .pInputText {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .pPassword {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .pButtonPrimary {
    background: linear-gradient(90deg, #007bff, #00d2ff);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
  }

  .pButtonSecondary {
    background: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 15px;
    color: #333;
    font-weight: bold;
    margin-top: 10px;

    &.div {
      display: flex;
      flex-direction: row;
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .pi-user-plus {
        display: flex;
        justify-content: start;
      }
    }
  }

  .p-calendar {
    width: 100% !important;
  }
  .loginDemoOverlay .formGroup label {
    text-align: start !important;
  }

  .divider {
    margin: 20px 0;
    color: #666;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
  }

  .errorMessage {
    color: #d9534f;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
  }
}

.popup-content {
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.popup-content p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.popup-content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.popup-content ul li {
  background-color: #f1fdff;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1.1em;
}

.popup-content ul li:nth-child(odd) {
  background-color: #f1fdff;
}

.popup-content .results-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.popup-content .sub-header {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 23px;
  text-align: justify;
  font-weight: 400;
}

.popup-content .encouragement-message {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 500;
}

.popup-content .no-results-message {
  font-size: 17px;
  font-weight: 500;
  background-color: #f1fdff;
  border-radius: 5px;
  padding: 10px;
}

.popup-content .alternative-majors-message {
  padding: 0px 10px 15px;
  font-size: 19px;
  font-weight: 600;
}

.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.popup-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.popup-button:hover {
  background-color: #0056b3;
}

.popup-button:focus {
  outline: none;
}

//#region Info Card
.infoCardContainer {
  perspective: 1000px;
  width: 300px;
  height: 551px;
  //margin: auto;
}

.infoCard {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.infoCardFlipped {
  transform: rotateY(180deg);
}

.infoCardFront,
.infoCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.infoCardFront {
  background-color: #fff;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 12px 16.2px rgba(117, 139, 238, 0.75);
  overflow: hidden;

  &.green {
    box-shadow: 0 5px 16.2px rgba(0, 168, 7, 0.75);
  }
}

.infoCardBack {
  background-color: #fff;
  border-radius: 13px;
  transform: rotateY(180deg);
  padding: 20px;
  box-shadow: 0 12px 16.2px rgba(117, 139, 238, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.green {
    box-shadow: 0 5px 16.2px rgba(0, 168, 7, 0.75);
  }
}

.infoCardFrontImg {
  height: 200px;
  // width: 357px;
}

.infoCardFrontDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  margin: 0;
  text-align: justify;
  font-weight: 200;
}

.infoCardFrontTitle {
  color: #758bee;
  max-width: 100%;
  font-size: 22px;
  font-weight: 300;
  display: inline-block;
  margin: 0;
  &.green {
    color: #00cd21;
  }
}

.infoCardFrontSubheader {
  position: relative;
  color: #292929;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  min-height: 56px;
}

.infoCardFrontDescriptionContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.infoCardFrontDescriptionShadow {
  height: 129px;
  width: 100%;
  box-shadow: inset 0px -29px 15px rgba(255, 255, 255, 0.7);
  position: absolute;
}

.infoCardFrontDescription {
  letter-spacing: 0.02em;
  font-size: 15px;
  line-height: 18px;
  // color: #8e8e8e;
  color: #8e8e8e;
  font-weight: 300;
  margin: 0;
  padding: 0;
  height: 129px;
  overflow: hidden;
  width: 100%;
}

.infoCardFrontButtonContainer {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoCardBackTitle {
  color: #758bee;
  max-width: 100%;
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 10px;
  &.green {
    color: #00cd21;
  }
}

.infoCardBackDescriptionContainer {
  height: 100%;
  width: 100%;
  margin: auto;
  overflow: hidden;
  // overflow-y: auto;
  scrollbar-width: none;
}

.infoCardBackDescription {
  letter-spacing: 0.02em;
  font-size: 15px;
  line-height: 18px;
  color: #8e8e8e;
  height: 100%;
  text-align: justify;
}
//#endregion

//#region Popup

.p-dialog-content p {
  text-align: center !important;
}
#contentPopup {
  .p-dialog-header {
    border: white;
    display: none;

    .p-dialog-header-icons {
      button {
        display: none;
      }
    }
  }
}
.smthg {
  border: 1px solid red !important;
  color: #002955 !important;
  background: #00cd21 !important;
  text-decoration: #004d40 !important;
}

canvas {
  min-height: 95px !important;
  min-width: 270px !important;
  width: 55% !important;
  height: auto !important;
}

.p-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 6px 6px 0px 0px;
  p {
    padding: 50px 30px;
    margin-bottom: 0;
  }
}
.resultPopup_content {
  .popupFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    button {
      font-size: 18px;
    }
  }
  .popup-content ul {
    font-size: 18px;
    font-weight: 600;
  }
}

#resultPopup_content {
  .popup-content ul {
    font-size: 18px;
    font-weight: 600;
  }
}

.p-dialog .p-dialog-footer {
  padding: 10px 0px 20px 0px;
}

.p-dialog {
  width: unset !important;
}

.share-results-button {
  display: flex;
  margin: 30px auto 0px;
  padding: 5px 15px !important;

  font-size: 14px;
}

.popupFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#resultPopup {
  .p-dialog-header-icons {
    button {
      display: none;
    }
  }
  .p-dialog-content {
    border-radius: 0 !important;
    background-color: #e2f3ff;
    p {
      padding: 0px 10px !important;
    }
  }
  .p-dialog-header {
    padding: 20px 24px 0px !important;
    text-align: center;
    background-color: #e2f3ff;
    pointer-events: none !important;
  }

  .p-dialog-footer {
    background-color: #e2f3ff;
  }

  .name {
    font-weight: 800;
    font-size: 20px;
    color: #263a5b;
  }

  .details {
    font-weight: 400;
    font-size: 15px;
    color: #263a5b;
    text-align: center;
  }

  .p-dialog-content {
    border-radius: 0 !important;
  }

  .detailsEn {
    font-size: 17px;
    margin: 10px 0px;
    direction: ltr !important;
  }

  .detailsAr {
    font-size: 19px;
    font-weight: 100 !important;
    margin: 10px 0px;
    direction: rtl !important;
  }

  hr {
    margin: 2px 0px !important;
    padding: 0 !important;
    background-color: #fff;
  }
}

.skills-note {
  padding: 10px 0px !important;
  text-align: justify !important;
  color: #7f7f7f;
}
#zeroResultPopup {
  width: 80% !important;
  .p-dialog-header-icons {
    display: none;

    button {
      display: none;
    }
  }

  .p-dialog-content {
    border-radius: 0 !important;
    background-color: #e2f3ff;
    p {
      padding: 10px 10px !important;
    }
  }

  .p-dialog-header {
    padding: 20px 24px 0px !important;
    background-color: #e2f3ff;
    pointer-events: none;
  }

  .p-dialog-footer {
    background-color: #e2f3ff;
  }

  .name {
    //font-weight: 800;
    font-size: 20px;
    color: #263a5b;
  }

  .details {
    font-weight: 400;
    font-size: 15px;
    color: #263a5b;
  }

  .p-dialog-content {
    border-radius: 0 !important;
  }

  .detailsEn {
    font-size: 17px;
    margin: 10px 0px;
    direction: ltr !important;
  }

  .detailsAr {
    font-size: 20px;
    font-weight: 100 !important;
    margin: 10px 0px;
    direction: rtl !important;
  }

  hr {
    margin: 2px 0px !important;
    padding: 0 !important;
    background-color: #fff;
  }
}

#purchasePopup {
  width: 40% !important;
  .p-dialog-header-icons {
    button {
      display: none;
    }
  }

  .p-dialog-header {
    padding: 15px 30px !important;
    text-align: center;
    .p-dialog-header p {
      font-weight: 600 !important;
    }
  }
  .p-dialog-content {
    border-radius: 0px !important;
    p {
      padding: 15px 30px !important;
    }
  }
}
#barGraphPopup {
  width: 70% !important;
  .p-dialog-content {
    border-radius: 0 !important;
  }
  .p-dialog-header-icons {
    button {
      display: none;
    }
  }

  .p-dialog-header {
    //text-align: center;
    pointer-events: none !important;
  }

  .p-dialog-title {
    font-size: 19px;
    font-weight: 400;
  }
}

.displayResultSentence {
  text-align: justify;
  font-size: 18px;
  padding: 5px 27px;
  font-weight: 100;
  font-family: Arial, sans-serif !important;
}

#warningPopup {
  width: 90% !important;

  .custom-header {
    display: flex;
    justify-content: center;
  }
  .p-dialog-content p {
    font-size: 19px !important;
    padding: 30px 20px !important;
    text-align: center;
  }
  .p-dialog-content {
    border-radius: 0 !important;
  }
  .p-dialog-header-icons {
    button {
      display: none;
    }
  }
  .p-dialog-header {
    pointer-events: none;
    color: #f8312f;
    font-size: 20px;
    font-weight: 800;
    text-align: center !important;
    padding: 24px 24px 0px 24px !important;
  }

  .button {
    background-color: #f8312f !important;
    color: #fff !important;
    font-size: 16px;
    padding: 19px 24px;
  }
}

#infoPopup {
  width: 70% !important;
  .p-dialog-header-icons {
    button {
      display: none;
    }
  }
  .p-dialog-header {
    display: none !important;
    p {
      padding: 20px;
    }
  }
  .p-dialog-content {
    border-radius: 6px 6px 0px 0px !important;
  }
}

#infoPopup .p-dialog-content {
  border-radius: 0 !important;
}

.p-chart {
  display: flex;
  justify-content: center;
  padding: 30px 20px !important;
}

.no-scroll {
  overflow: hidden;
}

.quiz-definitions {
  margin: 20px;
  font-family: Arial, sans-serif;
}
.quiz-definitions h4 {
  font-size: 19px;
  font-weight: 600;
}
.quiz-definition {
  margin-bottom: 18px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.quizTitlePopup {
  font-size: 17px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.quizDescriptionPopup {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

//#endregion

//#region TestPage

.TestPage {
  display: flex;
  flex-direction: column !important;
  text-align: start;

  .contentTestDesktop {
    background-image: url('..\\..\\public\\images\\Testpicture.svg');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    width: 23.5%;
    height: 105vh;
    position: fixed;
    top: 0;
    z-index: -1;
    margin-top: -23px;

    &.before {
      right: unset;
      left: 0;
    }
    &.after {
      background-image: url('..\\..\\public\\images\\loginbg.jpeg');
      right: 0;
      left: unset;
      background-position: right;
      background-position-x: 93%;
    }
    .contentHolder {
      background-image: url('..\\..\\public\\images\\bgcontent.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      width: 80%;
      right: 0;
      top: 35%;
      .content {
        font-size: 20px;
        min-height: 200px;
        color: #fff;
        font-weight: 500;
        text-align: center;
        width: 80%;
        padding: 10px;
        margin: auto;
        z-index: 20;
        display: flex;
        align-items: center;
      }
    }
  }

  .contentTestMobile {
    background-image: url('..\\..\\public\\images\\Testpicturemobile.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 40vh;
    position: relative;
    z-index: 0;
    margin-top: -23px;
    .content {
      position: absolute;
      font-size: 18px;
      color: #0056b3;
      top: 35%;
      font-weight: 500;
      text-align: center;
      width: 60%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

//#endregion

//#region language switcher

.toggle-container {
  width: 140px;
  height: 30px;
  background-color: #002955;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  /* transition: all 0.8s ease; */
}

.toggle-button {
  width: 50%;
  height: 100%;
  background-color: #2f93feef;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  position: absolute;
  border: 0 0 1px 1px #fff;
  transition: all 0.3s ease;
  &.left {
    left: 0;
    right: 70;
  }
}

.toggle-options {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 1;
}

.toggle-options span {
  color: white;
  display: flex;
  align-items: center;
  /* font-weight: bold; */
  font-size: 13px;
  opacity: 0.5;

  .active {
    opacity: 1;
    transition: all 0.5s ease;
  }
}
.toggle-options .active {
  opacity: 1;
}

//#endregion

//Admin Page

.adminTools {
  .p-toolbar {
    width: 85% !important;
    margin: auto;
    margin-top: 25px;
  }

  button {
    border-radius: 24px;
    height: 35px;
    margin-left: 20px;
  }
}

.admin-header {
  font-size: 45px;
  font-weight: 600;
  color: #002955;
  margin-bottom: 200px;
  width: 85%;
  margin: auto;

  h1 {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  hr {
    margin-bottom: 25px;
  }
  p {
    font-size: 16px;
    font-weight: 300;
  }

  li {
    font-size: 16px;
    font-weight: 300;
  }
}

.p-tag.p-tag-rounded {
  width: 80px;
}

.tagText {
  margin-right: 5px;
  margin-left: 5px;
  height: 20px;
  min-width: 80px !important;
}

.export {
  background-color: #a855f7;
}

.p-datatable-wrapper {
  border-radius: 6px !important;
}

#feedbackPopUp,
#ratingPopUp {
  width: 35% !important;
  .p-dialog-header-icons {
    button {
      display: none;
    }
  }
  .p-dialog-content {
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    padding: 20px !important;
  }
  .p-dialog-header {
    pointer-events: none !important;
  }
  .css-jm3i9k {
    width: unset !important;
  }

  .p-dialog-content p {
    padding: 20px 10px !important;
    margin-bottom: 0;
  }

  .css-14nmbys {
    padding: 0 !important;
    box-shadow: none !important;
  }
}

.ratingHolder {
  display: flex;
  flex-direction: column;

  .css-d0uhtl {
    margin: 0 !important;
  }
}

#ratingPopUp {
  .css-zfx7vw-MuiInputBase-root-MuiOutlinedInput-root {
    direction: ltr;
  }
}

#pr_id_1_header_0 {
  color: #8796a5 !important;
}

.contactHolder {
  flex-direction: column !important;
  text-align: start;
  height: 100vh;
  background-image: url('..\\..\\public\\images\\loginbg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .holder {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.emailContent {
  margin-left: 30%;
  //width: 500px;

  .email {
    font-size: 17px;
    line-height: 16px;
    color: #666;
    margin-bottom: 40px;
  }
  .text {
    font-weight: 300;
    color: #666;
  }
}
