// Core Value
.coreValueContainer .containerHolder .card .title {
  display: flex;
  justify-content: start;
}

// Menu Bar
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-menuitem-text {
  margin-right: 10px;
}

.TestPage {
  // align-items: flex-end !important;
  .contentTestDesktop {
    // right: 0;
    // left: unset;
  }

  .muiStepperContainer {
    .muiStepperLabel {
      text-align: start;
    }
    .muiStepperContentHolder {
      margin-right: 12px;
      padding-right: 20px;
      padding-left: 8px;
      border-left: none !important;
      border-right: none !important;
    }
    .MuiStepConnector-root {
      margin-right: 12px;

      .MuiStepConnector-line {
        display: block;
        border-color: none !important;
        border-right-style: none !important;
        border-right-width: none !important;
        min-height: 24px;
        border-left: none;
      }
    }
  }
}
.MuiFormControlLabel-label {
  font-family: 'Inter var', sans-serif !important;
  font-size: 16px;
}
.MuiTypography-root {
  font-family: 'Inter var', sans-serif !important;
}
.muiStepperOptionLabel {
  font-family: 'Inter var', sans-serif !important;
}
.stepperHolder .card label {
  margin-right: 0;
  padding-right: 0;
}

.muiStepperContentHolder {
  margin-right: 0;
  padding-right: 0;
}
.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  margin-right: 10px;
}

.loginDemoOverlay .formGroup label {
  text-align: start !important;
}

.p-button-icon {
  margin: 0px 7px;
}

#ratingPopUp {
  .css-zfx7vw-MuiInputBase-root-MuiOutlinedInput-root {
    direction: rtl;
  }
}
